<template>
  <div class="data-box">
    <van-nav-bar
        title="住宿详情"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">负责人</span>
          <span class="span-name align-right">{{ liveInfo.name ? liveInfo.name : "无" }}</span>
          <a :href="'tel:' + liveInfo.phone" class="iconfont icon-dianhua1" v-show="liveInfo.phone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-title align-left">
          安排结果
        </div>
        <div class="body-div-item" v-for="(item,index) in arrangeKeyValueList" :key="index">
          <span class="span-label">{{ item.key }}</span>
          <span class="span-value align-right">{{ item.value }}</span>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-title align-left">
          实际结果
        </div>
        <div class="body-div-item" v-for="(item,index) in actualKeyValueList" :key="index">
          <span class="span-label" style="width: 30%;color: #8B8FAD;">{{ item.key }}</span>
          <span class="span-value align-right">{{ item.value }}</span>
        </div>
      </div>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  stayArrangeShow
} from "@/api/index";

export default {
  name: "orgCheck_live",
  data() {
    return {
      liveInfo: {
        name: "",
        phone: ""
      },
      arrangeKeyValueList: [
        { key: "入住酒店", value: ""},
        { key: "入住房型", value: ""},
        { key: "入住时间", value: ""},
        { key: "退房时间", value: "2"},
        { key: "住宿要求", value: ""}
      ],
      actualKeyValueList: [
        { key: "办理入住时间", value: ""},
        { key: "办理退房时间", value: ""},
        { key: "入住房号", value: ""}
      ]
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getSelectOPDetails.activitySets;
      activitySets.forEach(item => {
        if(item.relateType === 2){
          that.liveInfo.name = item.chargeName;
          that.liveInfo.phone = item.chargePhone;
          let date = item.noteNameDateStop.split("~");
          that.arrangeKeyValueList[1].value = item.checkTypeNumArticle;
          that.arrangeKeyValueList[2].value = date[0];
          that.arrangeKeyValueList[3].value = date[1];
        }
        if(item.relateType === 9){
          that.arrangeKeyValueList[0].value = item.chargeName;
          let noteNameDateStop = JSON.parse(item.noteNameDateStop);
          that.arrangeKeyValueList[4].value = noteNameDateStop.checkInRequire;
        }
      })
      that.getLiveInfo();
    },
    getLiveInfo(){
      let that = this;
      let params = {
        ayId: that.$store.getters.getSelectOPDetails.id
      };
      stayArrangeShow(params).then(res => {
        that.$tool.resValidation(res,() => {
          let checkInData = res.data.aoscsList[0] ? res.data.aoscsList[0] : {};
          let checkOutData = res.data.aoscsList[1] ? res.data.aoscsList[1] : {};
          that.actualKeyValueList[0].value = checkInData.stayDate ? checkInData.stayDate : "未入住";
          that.actualKeyValueList[1].value = checkOutData.stayDate ? checkOutData.stayDate : "未退房";
          that.actualKeyValueList[2].value = checkInData.roomEnergyFood ? checkInData.roomEnergyFood : "未入住";
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>